import {
  Modal,
  Stack,
  IconButton,
  ITheme,
  IButtonStyles
} from '@fluentui/react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useClasses } from 'shared/hooks/useClasses'
import { useStyles } from 'shared/hooks/useStyles'
import { updateDueDiligenceActions } from '../store/actions'

const getClasses = (theme: ITheme) => ({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch'
  },

  stackContentGridLayout: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(35%, 1fr))',
    gap: '10px',
    margin: '0px'
  },
  header: [
    theme.fonts.large,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      display: 'flex',
      alignItems: 'center',
      padding: '5px 10px 0px 10px',
      position: 'sticky',
      top: 0,
      zIndex: 3,
      backgroundColor: '#fff',
      color: '#0078D4',
      fontWeight: 500
    }
  ],
  modalBody: {
    minWidth: 447,
    fontSize: 14,
    fontWeight: 500,
    padding: '0 10px'
  }
})

const getThemedButtonStyles = (theme: ITheme): IButtonStyles => ({
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
})

const ViewMoreBulkUploadErrorModal: React.FC<{
  bulkUpload: any
  closeModal: () => void
}> = ({ bulkUpload, closeModal }) => {
  console.log('bulkUpload', bulkUpload)

  const dispatch = useDispatch()

  const resetModelSettings = () => {
    dispatch(updateDueDiligenceActions.clear())
  }

  const contentStyles = useClasses(getClasses)
  const iconButtonStyles = useStyles(getThemedButtonStyles)

  return (
    <>
      <Modal
        isOpen={true}
        onDismiss={() => {}}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <span style={{ marginLeft: 10 }}>{' Upload Error Messages'}</span>
          <IconButton
            styles={iconButtonStyles}
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close popup modal"
            onClick={() => {
              resetModelSettings()
              closeModal()
            }}
          />
        </div>

        <div
          style={{
            padding: '0px 20px',
            position: 'sticky',
            zIndex: 3,
            backgroundColor: '#fff',
            top: 50
          }}
        >
          <hr />
        </div>

        <div style={{ marginBottom: 10 }}>
          <Stack horizontal={true} tokens={{ childrenGap: 15, padding: 0 }}>
            <ul style={{ padding: '0px 20px', margin: '0px 10px' }}>
              {bulkUpload?.map((text: string, index: number) => (
                <li key={index} style={{ color: 'red', padding: 5 }}>
                  {text}
                </li>
              ))}
            </ul>
          </Stack>
        </div>
      </Modal>
    </>
  )
}

export default ViewMoreBulkUploadErrorModal
