import { ITheme } from '@fluentui/react'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getTheme } from '../../store/ui/selectors'

export const useStyles = <TStyleSet>(
  getThemedStyleSet: (theme: ITheme) => TStyleSet
): TStyleSet => {
  const theme = useSelector(getTheme)
  return useMemo(() => getThemedStyleSet(theme), [theme, getThemedStyleSet])
}
