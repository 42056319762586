import { ISecurity } from 'api/security.types'
import { createAction, ActionType } from 'typesafe-actions'
import {
  IAuditRequestByRcmSecIdApiRes,
  IDueDiligenceExportReq,
  IGetDueDiligence,
  ISearchResult,
  IUpdateDueDiligence,
  IUploadIDDFiles,
  IUploadIDDRequest
} from '../api/types'

export const FETCH_DUE_DILIGENCE_REQUEST = `@modules/@security/@duediligences/FETCH_DUE_DILIGENCE_REQUEST`
export const FETCH_DUE_DILIGENCE_SUCCESS = `@modules/@security/@duediligences/FETCH_DUE_DILIGENCE_SUCCESS`
export const FETCH_DUE_DILIGENCE_FAILURE = `@modules/@security/@duediligences/FETCH_DUE_DILIGENCE_FAILURE`
export const FETCH_DUE_DILIGENCE_CLEAR = `@modules/@security/@duediligences/FETCH_DUE_DILIGENCE_CLEAR`

export const dueDiligenceFetchActions = {
  request: createAction(FETCH_DUE_DILIGENCE_REQUEST)<string | undefined>(),
  success: createAction(FETCH_DUE_DILIGENCE_SUCCESS)<IGetDueDiligence[]>(),
  failure: createAction(FETCH_DUE_DILIGENCE_FAILURE)<Error>(),
  clear: createAction(FETCH_DUE_DILIGENCE_CLEAR)()
}
export const UPDATE_DUE_DILIGENCE_REQUEST = `@modules/@security/@duediligences/UPDATE_DUE_DILIGENCE_REQUEST`
export const UPDATE_DUE_DILIGENCE_SUCCESS = `@modules/@security/@duediligences/UPDATE_DUE_DILIGENCE_SUCCESS`
export const UPDATE_DUE_DILIGENCE_FAILURE = `@modules/@security/@duediligences/UPDATE_DUE_DILIGENCE_FAILURE`
export const UPDATE_DUE_DILIGENCE_CLEAR = `@modules/@security/@duediligences/UPDATE_DUE_DILIGENCE_CLEAR`

export const updateDueDiligenceActions = {
  request: createAction(UPDATE_DUE_DILIGENCE_REQUEST)<IUpdateDueDiligence>(),
  success: createAction(UPDATE_DUE_DILIGENCE_SUCCESS)<any>(),
  failure: createAction(UPDATE_DUE_DILIGENCE_FAILURE)<Error>(),
  clear: createAction(UPDATE_DUE_DILIGENCE_CLEAR)()
}

export const FETCH_APPROVAL_STATUS_REQUEST = `@modules/@security/@duediligences/FETCH_APPROVAL_STATUS_REQUEST`
export const FETCH_APPROVAL_STATUS_SUCCESS = `@modules/@security/@duediligences/FETCH_APPROVAL_STATUS_SUCCESS`
export const FETCH_APPROVAL_STATUS_FAILURE = `@modules/@security/@duediligences/FETCH_APPROVAL_STATUS_FAILURE`
export const FETCH_APPROVAL_STATUS_CLEAR = `@modules/@security/@duediligences/FETCH_APPROVAL_STATUS_CLEAR`

export const fetchApprovalStatusActions = {
  request: createAction(FETCH_APPROVAL_STATUS_REQUEST)<any>(),
  success: createAction(FETCH_APPROVAL_STATUS_SUCCESS)<any>(),
  failure: createAction(FETCH_APPROVAL_STATUS_FAILURE)<Error>(),
  clear: createAction(FETCH_APPROVAL_STATUS_CLEAR)()
}
export const FETCH_AUDIT_REQUEST_REQUEST = `@modules/@security/@duediligences/FETCH_AUDIT_REQUEST_REQUEST`
export const FETCH_AUDIT_REQUEST_SUCCESS = `@modules/@security/@duediligences/FETCH_AUDIT_REQUEST_SUCCESS`
export const FETCH_AUDIT_REQUEST_FAILURE = `@modules/@security/@duediligences/FETCH_AUDIT_REQUEST_FAILURE`
export const FETCH_AUDIT_REQUEST_CLEAR = `@modules/@security/@duediligences/FETCH_AUDIT_REQUEST_CLEAR`

export const getAuditRequestActions = {
  request: createAction(FETCH_AUDIT_REQUEST_REQUEST)<string>(),
  success: createAction(
    FETCH_AUDIT_REQUEST_SUCCESS
  )<IAuditRequestByRcmSecIdApiRes>(),
  failure: createAction(FETCH_AUDIT_REQUEST_FAILURE)<Error>(),
  clear: createAction(FETCH_AUDIT_REQUEST_CLEAR)()
}

export const EXPORT_DUEDILIGENCE = `@modules/@security/@duediligences/EXPORT_DUEDILIGENCE`
export const EXPORT_DUEDILIGENCE_SUCCESS = `@modules/@security/@duediligences/EXPORT_DUEDILIGENCE_SUCCESS`
export const EXPORT_DUEDILIGENCE_FAILURE = `@modules/@security/@duediligences/EXPORT_DUEDILIGENCE_FAILURE`

export const exportDueDiligenceToExcelActions = {
  request: createAction(EXPORT_DUEDILIGENCE)<IDueDiligenceExportReq>(),
  success: createAction(EXPORT_DUEDILIGENCE_SUCCESS)(),
  failure: createAction(EXPORT_DUEDILIGENCE_FAILURE)<Error>()
}

export const SHARE_CLASS_REQUEST = `@modules/@security/@duediligences/SHARE_CLASS_REQUEST`
export const SHARE_CLASS_SUCCESS = `@modules/@SPA/SHARE_CLASS_SUCCESS`
export const SHARE_CLASS_FAILURE = `@modules/@SPA/SHARE_CLASS_FAILURE`
export const SHARE_CLASS_CLEAR = `@modules/@SPA/SHARE_CLASS_CLEAR`

export const getShareClassActions = {
  request: createAction(SHARE_CLASS_REQUEST)<any>(),
  success: createAction(SHARE_CLASS_SUCCESS)<ISearchResult<ISecurity>>(),
  failure: createAction(SHARE_CLASS_FAILURE)<Error>(),
  clear: createAction(SHARE_CLASS_CLEAR)()
}

export const UPLOAD_IDD_FILE_REQUEST = `@modules/@security/@duediligences/UPLOAD_IDD_FILE_REQUEST`
export const UPLOAD_IDD_FILE_SUCCESS = `@modules/@security/@duediligences/UPLOAD_IDD_FILE_SUCCESS`
export const UPLOAD_IDD_FILE_FAILURE = `@modules/@security/@duediligences/UPLOAD_IDD_FILE_FAILURE`
export const UPLOAD_IDD_FILE_CLEAR = `@modules/@security/@duediligences/UPLOAD_IDD_FILE_CLEAR`

export const uploadIDDActions = {
  request: createAction(UPLOAD_IDD_FILE_REQUEST)<IUploadIDDRequest>(),
  success: createAction(UPLOAD_IDD_FILE_SUCCESS)<IUploadIDDFiles>(),
  failure: createAction(UPLOAD_IDD_FILE_FAILURE)<Error>(),
  clear: createAction(UPLOAD_IDD_FILE_CLEAR)()
}

export type GetDueDiligenceActionType = ActionType<
  typeof dueDiligenceFetchActions
>
export type UpdateDueDiligenceActionType = ActionType<
  typeof updateDueDiligenceActions
>
export type FetchApprovalStatusActionType = ActionType<
  typeof fetchApprovalStatusActions
>
export type GetAuditRequestActionsType = ActionType<
  typeof getAuditRequestActions
>
export type ShareClassActionsType = ActionType<typeof getShareClassActions>
export type UploadIDDActionsType = ActionType<typeof uploadIDDActions>

export type DueDiligencesActionsTypes =
  | GetDueDiligenceActionType
  | UpdateDueDiligenceActionType
  | FetchApprovalStatusActionType
  | GetAuditRequestActionsType
  | ActionType<typeof exportDueDiligenceToExcelActions>
  | ShareClassActionsType
  | UploadIDDActionsType
