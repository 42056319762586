import { IColumnDefinition } from 'features/Lists/core/contracts/IColumnDefinition'

enum ColumnIdsEnum {
  id = 'id',
  rcm_sec_id = 'rcm_sec_id',
  cusip = 'cusip',
  ticker = 'ticker',
  preRefundPrice = 'preRefundPrice',
  securitydescription = 'securitydescription',
  shareclass = 'shareclass',
  shareClassCategory = 'shareClassCategory',
  assettype = 'assettype',
  assetsubtype = 'assetsubtype',
  l1description = 'l1description',
  l2description = 'l2description',
  l3description = 'l3description',
  l4description = 'l4description',
  nextDividendDate = 'nextDividendDate',
  source = 'source',
  maturityDate = 'maturityDate',
  preRefundDate = 'preRefundDate',
  approvalStatus = 'approvalStatus',
  approvalEffectiveDate = 'approvalEffectiveDate',
  selectListEffectiveDate = 'selectListEffectiveDate',
  selectList = 'selectList'
}

export const DueDiligenceColumnIds = { ...ColumnIdsEnum }
export type DueDiligenceColumnIds = typeof DueDiligenceColumnIds

export const DueDiligenceColumnDefinitions: Record<string, IColumnDefinition> =
  {
    [DueDiligenceColumnIds.cusip]: {
      id: DueDiligenceColumnIds.cusip,
      name: 'CUSIP',
      type: 'string',
      width: 110,
      filterable: true,
      sortable: true,
      searchable: true,
      facetable: false,
      dataPath: 'cusip'
    },
    [DueDiligenceColumnIds.ticker]: {
      id: DueDiligenceColumnIds.ticker,
      name: 'Symbol',
      type: 'string',
      width: 100,
      filterable: true,
      sortable: true,
      searchable: true,
      facetable: false,
      dataPath: 'ticker'
    },
    [DueDiligenceColumnIds.preRefundPrice]: {
      id: DueDiligenceColumnIds.preRefundPrice,
      name: 'Fund Portfolio Name',
      type: 'string',
      width: 160,
      filterable: true,
      sortable: true,
      searchable: true,
      facetable: false,
      dataPath: 'preRefundPrice'
    },
    [DueDiligenceColumnIds.securitydescription]: {
      id: DueDiligenceColumnIds.securitydescription,
      name: 'Security Description',
      type: 'string',
      width: 400,
      filterable: true,
      sortable: true,
      searchable: true,
      facetable: false,
      dataPath: 'securitydescription'
    },
    [DueDiligenceColumnIds.shareclass]: {
      id: DueDiligenceColumnIds.shareclass,
      name: 'Share Class',
      type: 'string',
      width: 100,
      filterable: true,
      sortable: true,
      searchable: true,
      facetable: true,
      dataPath: 'shareclass'
    },
    [DueDiligenceColumnIds.shareClassCategory]: {
      id: DueDiligenceColumnIds.shareClassCategory,
      name: 'Share Class Category',
      type: 'string',
      width: 100,
      filterable: true,
      sortable: true,
      searchable: true,
      facetable: true,
      dataPath: 'shareClassCategory'
    },
    [DueDiligenceColumnIds.assettype]: {
      id: DueDiligenceColumnIds.assettype,
      name: 'Asset Type',
      type: 'string',
      width: 115,
      filterable: true,
      sortable: true,
      searchable: true,
      facetable: true,
      dataPath: 'assettype',
      validFacetValues: ['EQUITY', 'MUTUAL FUNDS']
    },
    [DueDiligenceColumnIds.assetsubtype]: {
      id: DueDiligenceColumnIds.assetsubtype,
      name: 'Asset Sub-Type',
      type: 'string',
      width: 140,
      filterable: true,
      sortable: true,
      searchable: true,
      facetable: true,
      dataPath: 'assetsubtype',
      validFacetValues: ['ETF', 'OTHER', 'BOND', 'EQUITY']
    },
    [DueDiligenceColumnIds.l1description]: {
      id: DueDiligenceColumnIds.l1description,
      name: 'Level 1',
      type: 'string',
      width: 110,
      filterable: true,
      sortable: true,
      searchable: true,
      facetable: true,
      dataPath: 'l1description'
    },
    [DueDiligenceColumnIds.l2description]: {
      id: DueDiligenceColumnIds.l2description,
      name: 'Level 2',
      type: 'string',
      width: 150,
      filterable: true,
      sortable: true,
      searchable: true,
      facetable: true,
      dataPath: 'l2description'
    },
    [DueDiligenceColumnIds.l3description]: {
      id: DueDiligenceColumnIds.l3description,
      name: 'Level 3',
      type: 'string',
      width: 150,
      filterable: true,
      sortable: true,
      searchable: true,
      facetable: true,
      dataPath: 'l3description'
    },
    [DueDiligenceColumnIds.l4description]: {
      id: DueDiligenceColumnIds.l4description,
      name: 'Level 4',
      type: 'string',
      width: 150,
      filterable: true,
      sortable: true,
      searchable: true,
      facetable: true,
      dataPath: 'l4description'
    },
    [DueDiligenceColumnIds.approvalStatus]: {
      id: DueDiligenceColumnIds.approvalStatus,
      name: 'Approval Status',
      type: 'string',
      width: 180,
      filterable: true,
      sortable: true,
      searchable: true,
      facetable: true,
      dataPath: 'approvalStatus'
    },
    [DueDiligenceColumnIds.approvalEffectiveDate]: {
      id: DueDiligenceColumnIds.approvalEffectiveDate,
      name: 'Approval Effective Date',
      type: 'date',
      width: 100,
      filterable: true,
      sortable: true,
      searchable: false,
      facetable: true,
      dataPath: 'approvalEffectiveDate'
    },
    [DueDiligenceColumnIds.selectList]: {
      id: DueDiligenceColumnIds.selectList,
      name: 'Focus List',
      type: 'string',
      width: 100,
      filterable: true,
      sortable: true,
      searchable: true,
      facetable: true,
      dataPath: 'selectList'
    },
    [DueDiligenceColumnIds.selectListEffectiveDate]: {
      id: DueDiligenceColumnIds.selectListEffectiveDate,
      name: 'Focus List Effective Date',
      type: 'date',
      width: 100,
      filterable: true,
      sortable: true,
      searchable: false,
      facetable: true,
      dataPath: 'selectListEffectiveDate'
    },
    [DueDiligenceColumnIds.rcm_sec_id]: {
      id: DueDiligenceColumnIds.rcm_sec_id,
      name: 'Action',
      type: 'string',
      width: 60,
      className: 'iddActionColumn',
      filterable: false,
      sortable: false,
      searchable: false,
      facetable: false,
      dataPath: 'rcm_sec_id'
    }
  }
