import axios from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { IDragDropFile } from '../components/DragDrop'
import {
  IAuditRequestByRcmSecIdApiRes,
  IIDDUploadApi,
  IUpdateDueDiligence,
  IUpdateDueDiligenceApiRes
} from './types'

const apiPathIdd = 'cdm/duediligence'

export const getDueDiligencesService = (options: IApiOptions) => {
  const apiName = 'getRequest'
  const url = `${options.apiRoot}/${apiPathIdd}/${apiName}`
  return axios
    .get(`${url}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export const updateDueDiligenceService = (
  req: IUpdateDueDiligence,
  options: IApiOptions
) => {
  const apiName = 'setRequest'
  const url = `${options.apiRoot}/${apiPathIdd}/${apiName}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IUpdateDueDiligenceApiRes>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const getIDDApprovalStatusService = (options: IApiOptions) => {
  const apiName = 'getApprovalStatus'
  const url = `${options.apiRoot}/${apiPathIdd}/${apiName}`
  return axios
    .get(`${url}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export const getIDDAuditRequestService = (
  rcm_sec_id: string,
  options: IApiOptions
) => {
  const apiName = 'getAuditRequest'
  const url = `${options.apiRoot}/${apiPathIdd}/${apiName}?rcm_sec_id=${rcm_sec_id}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IAuditRequestByRcmSecIdApiRes>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const uploadIDDFileService = (
  req: IIDDUploadApi,
  options: IApiOptions,
  onUploadProgress?: (
    progressEvent: ProgressEvent<EventTarget>,
    file: IDragDropFile
  ) => void
) => {
  const apiName = 'bulkupload'
  const url = `${options.apiRoot}/${apiPathIdd}/${apiName}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const data = new FormData()
  data.append('attachment', req.docFile?.file ?? '')
  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken,
      onUploadProgress: onUploadProgress
        ? (progressEvent) => {
            onUploadProgress(progressEvent, req.docFile)
          }
        : undefined
    })
    .then((res) => res.data)
}
