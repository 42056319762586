import { Icon, Stack, ProgressIndicator, CommandButton } from '@fluentui/react'
import React, {
  useRef,
  useState,
  memo,
  useCallback,
  PropsWithChildren
} from 'react'
import { useSelector } from 'react-redux'
import { getUploadIDDState } from '../store/selectors'
import ViewMoreBulkUploadErrorModal from './ViewMoreBulkUploadErrorModal'

export interface IDragDropProps {
  WrapperStyle?: React.CSSProperties
  onDropFile: (
    file: IDragDropFile,
    onUploadSuccess: (file: IDragDropFile) => void,
    onUploadFail: (file: IDragDropFile) => void,
    onUploadProgress?: (
      progressEvent: ProgressEvent<EventTarget>,
      file: IDragDropFile
    ) => void
  ) => void
  allowedFileType?: string[]
}

const DragDropWrapperStyle: React.CSSProperties = {
  minHeight: 64,
  border: '3px dashed #b49d77',
  display: 'grid',
  alignItems: 'center',
  justifyItems: 'center'
}

export enum DragDropFileStatus {
  'PROCESSING' = 'PROCESSING',
  'SUCCESS' = 'SUCCESS',
  'FAILURE' = 'FAILURE'
}

export interface IDragDropFile {
  id: string
  file: File
  fileStatus?: DragDropFileStatus
  uploadPercentage?: number
}

interface IDragDropState {
  isDragging: boolean
  fileList: IDragDropFile[]
}

const DragDrop: React.FC<PropsWithChildren<IDragDropProps>> = (props) => {
  const refDragWrapper = useRef<HTMLDivElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const { WrapperStyle, onDropFile, allowedFileType }: IDragDropProps = props
  const [dragDropState, setDragDropState] = useState<IDragDropState>({
    isDragging: false,
    fileList: []
  })
  const { isDragging, fileList } = dragDropState
  const refFileList = useRef<IDragDropFile[]>([])
  const bulkUpload = useSelector(getUploadIDDState)
  refFileList.current = fileList
  const handleDragEnter = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      setDragDropState((prevState) => ({
        ...prevState,
        isDragging: true
      }))
    },
    []
  )

  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      setDragDropState((prevState) => ({
        ...prevState,
        isDragging: false
      }))
    },
    []
  )

  const fileInputClicked = () => {
    if (null !== fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = 'copy'
      }
      setDragDropState((prevState) => ({
        ...prevState,
        isDragging: true
      }))
    },
    []
  )

  const onUploadSuccess = useCallback((file: IDragDropFile) => {
    const referencedFiles = [...refFileList.current]
    const updatedFileList: IDragDropFile[] = referencedFiles.map(
      (statefile) => {
        if (statefile.id === file.id) {
          statefile.fileStatus = DragDropFileStatus.SUCCESS
        }
        return statefile
      }
    )

    setDragDropState((prevState) => ({
      ...prevState,
      fileList: [...updatedFileList]
    }))
  }, [])

  const onUploadFail = useCallback((file: IDragDropFile) => {
    const referencedFiles = [...refFileList.current]
    const updatedFileList: IDragDropFile[] = referencedFiles?.map(
      (statefile) => {
        if (statefile.id === file.id) {
          statefile.fileStatus = DragDropFileStatus.FAILURE
        }
        return statefile
      }
    )
    setDragDropState((prevState) => ({
      ...prevState,
      fileList: [...updatedFileList]
    }))
  }, [])

  const onUploadProgress = useCallback(
    (progressEvent: ProgressEvent<EventTarget>, file: IDragDropFile) => {
      const referencedFiles = [...refFileList.current]
      const updatedFileList: IDragDropFile[] = referencedFiles?.map(
        (statefile) => {
          if (statefile.id === file.id) {
            statefile.uploadPercentage = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            )
          }
          return statefile
        }
      )
      setDragDropState((prevState) => ({
        ...prevState,
        fileList: [...updatedFileList]
      }))
    },
    []
  )

  const onFileDrop = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const target = e.target as HTMLInputElement
    const files: IDragDropFile[] = [...(target?.files || [])]
      .filter((file) => {
        if (allowedFileType) {
          return allowedFileType.includes(file?.type)
        } else {
          return true
        }
      })
      .map(
        (file) =>
          ({
            id: `_${Math.random().toString(36)}`,
            file
          } as IDragDropFile)
      )

    setDragDropState((prevState) => ({
      ...prevState,
      isDragging: false,
      fileList: [...prevState.fileList, ...files]
    }))

    //call onDropFile
    files.forEach((file) => {
      onDropFile(file, onUploadSuccess, onUploadFail, onUploadProgress)
    })
  }

  const handleDragDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      const files: IDragDropFile[] = [...(event.dataTransfer?.files || [])]
        .filter((file) => {
          if (allowedFileType) {
            return allowedFileType.includes(file?.type)
          } else {
            return true
          }
        })
        .map(
          (file) =>
            ({
              id: `_${Math.random().toString(36)}`,
              file
            } as IDragDropFile)
        )

      setDragDropState((prevState) => ({
        ...prevState,
        isDragging: false,
        fileList: [...prevState.fileList, ...files]
      }))

      //call onDropFile
      files.forEach((file) => {
        onDropFile(file, onUploadSuccess, onUploadFail, onUploadProgress)
      })
    },
    [
      allowedFileType,
      onDropFile,
      onUploadFail,
      onUploadProgress,
      onUploadSuccess
    ]
  )

  const getProgressColor = (fileStatus?: DragDropFileStatus) => {
    if (fileStatus) {
      if (fileStatus === DragDropFileStatus.SUCCESS) {
        return 'green'
      }
      if (fileStatus === DragDropFileStatus.FAILURE) {
        return 'red'
      }
    } else {
      return 'rgb(0, 120, 212)'
    }
  }
  const viewMore = () => {
    setModalOpen(true)
  }
  const bulkUploadError = bulkUpload?.error?.message.split(',')

  return (
    <>
      <div style={{ backgroundColor: '#fff' }}>
        <div
          style={WrapperStyle ? WrapperStyle : DragDropWrapperStyle}
          ref={refDragWrapper}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDragDrop}
          onClick={fileInputClicked}
        >
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            onChange={onFileDrop}
            style={{ display: 'none' }}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/csv, application/vnd.ms-excel, text/csv"
          />
          <Icon
            iconName="BulkUpload"
            style={{
              fontSize: '32px',
              marginTop: '5px',
              color: isDragging ? 'rgb(0, 120, 212)' : '#ccc'
            }}
          />
          {props.children}
        </div>
      </div>
      <Stack>
        {fileList &&
          fileList.length > 0 &&
          fileList.map((fileItem, index) => {
            return (
              <div
                style={{ backgroundColor: '#fff', padding: '0px 5px 0px 10px' }}
                key={index}
              >
                <Stack.Item>
                  <div
                    style={{
                      color: getProgressColor(fileItem?.fileStatus)
                    }}
                  >
                    {fileItem.fileStatus === DragDropFileStatus.SUCCESS && (
                      <>
                        {fileItem?.file?.name}
                        <span style={{ color: '#0078D4' }}>
                          {' Successfully uploaded'}
                        </span>
                      </>
                    )}
                    {fileItem.fileStatus === DragDropFileStatus.FAILURE && (
                      <span style={{ color: '#ff0000' }}>
                        {bulkUpload && bulkUpload.error ? (
                          <ul
                            style={{
                              padding: '0px 0px 0px 10px',
                              margin: '0px',
                              fontSize: 12
                            }}
                          >
                            {bulkUploadError
                              ?.slice(0, 2)
                              ?.map((text: string, index: number) => (
                                <li
                                  key={index}
                                  style={{
                                    color: 'red',
                                    padding: 3,
                                    textIndent: '-8px'
                                  }}
                                >
                                  {text}
                                </li>
                              ))}
                          </ul>
                        ) : (
                          ''
                        )}
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }}
                        >
                          <CommandButton
                            styles={{
                              root: {
                                height: 20
                              }
                            }}
                            style={{ color: '#0078D4' }}
                            onClick={() => viewMore()}
                          >
                            view more...
                          </CommandButton>
                        </span>
                      </span>
                    )}
                  </div>
                  <div>
                    {!fileItem.fileStatus && (
                      <ProgressIndicator
                        percentComplete={fileItem.uploadPercentage}
                      />
                    )}
                  </div>
                </Stack.Item>
              </div>
            )
          })}
        {isModalOpen && (
          <ViewMoreBulkUploadErrorModal
            bulkUpload={bulkUploadError}
            closeModal={() => {
              setModalOpen(false)
            }}
          />
        )}{' '}
      </Stack>
    </>
  )
}

export default memo(DragDrop)
