import { ISearchParams } from 'api/common.types'
import { OdataFilterOperatorEnum, OdataPropertyFilterGroup } from 'api/odata'
import { search } from 'api/search'
import { ISecurity } from 'api/security.types'
import axios from 'axios'
import { format } from 'date-fns'
import { IColumnDefinition } from 'features/Lists/core/contracts/IColumnDefinition'
import { IExportDefinition } from 'features/Lists/core/contracts/IExportDefinition'
import { throttle, getValue, getMapping } from 'features/Lists/core/store/sagas'
import { keyBy } from 'lodash'
import { StrictEffect } from 'redux-saga/effects'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { exportDataToExcel } from 'shared/xlsx'
import { getRockefellerApiOptions } from 'store/shared'
import {
  all,
  call,
  cancelled,
  delay,
  put,
  takeEvery,
  takeLatest
} from 'typed-redux-saga'
import {
  getDueDiligencesService,
  getIDDApprovalStatusService,
  getIDDAuditRequestService,
  updateDueDiligenceService,
  uploadIDDFileService
} from '../api/duediligence'
import { ApprovalStatus, ISearchResult } from '../api/types'
import { DragDropFileStatus } from '../components/DragDrop'
import {
  dueDiligenceFetchActions,
  exportDueDiligenceToExcelActions,
  fetchApprovalStatusActions,
  getAuditRequestActions,
  getShareClassActions,
  updateDueDiligenceActions,
  uploadIDDActions
} from './actions'

function* handleFetchdueDiligence(
  action: ReturnType<typeof dueDiligenceFetchActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)

  try {
    const result = yield* call(
      getDueDiligencesService,
      apiOptions,
      action.payload
    )
    yield put(dueDiligenceFetchActions.success(result.data))
  } catch (e: any) {
    yield put(dueDiligenceFetchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

function* handleUpdateDueDiligence(
  action: ReturnType<typeof updateDueDiligenceActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const apiResp = yield* call(() =>
      updateDueDiligenceService(action.payload, options)
    )
    if (apiResp.errorCode === 0) {
      yield put(updateDueDiligenceActions.success(apiResp))
      yield put(dueDiligenceFetchActions.request(''))
    } else {
      yield put(
        updateDueDiligenceActions.failure(
          new Error(
            apiResp?.errormessage ||
              'Unable to update Due Diligence , please try after sometime.'
          )
        )
      )
    }
  } catch (e: any) {
    yield put(
      updateDueDiligenceActions.failure(
        new Error('Unable to update Due Diligence, please try after sometime.')
      )
    )
  }
}

function* handleApprovalStatusActions() {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() => getIDDApprovalStatusService(options))
    if (response.success) {
      yield put(fetchApprovalStatusActions.success(response.data))
    } else {
      yield put(
        fetchApprovalStatusActions.failure(
          new Error('Unable to get Approval Status')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchApprovalStatusActions.failure(e))
  }
}

function* handleAuditRequestBySecId(
  action: ReturnType<typeof getAuditRequestActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      getIDDAuditRequestService(action.payload, options)
    )

    if (response.success) {
      yield put(getAuditRequestActions.success(response))
    } else {
      yield put(
        getAuditRequestActions.failure(
          new Error('Unable to get Audit Request, please try after sometime.')
        )
      )
    }
  } catch (e: any) {
    yield put(getAuditRequestActions.failure(e))
  }
}

const initialChunkSize = 50
const chunkSize = 200
function* handleExportDueDiligenceToExcel(
  action: ReturnType<typeof exportDueDiligenceToExcelActions.request>
) {
  yield delay(300)
  const options: IApiOptions = yield call(getRockefellerApiOptions)
  const chunks: {
    index: number
    result: ISearchResult<ISecurity>
  }[] = []
  const baseRequestParams = yield* call(action.payload.requestParams)
  let totalCount = 0

  try {
    const peekResults = yield* call(
      search,
      'security' as const,
      {
        ...baseRequestParams,
        top: initialChunkSize,
        count: true
      },
      options
    )
    chunks.push({ index: 0, result: peekResults as ISearchResult<ISecurity> })

    totalCount = peekResults['@odata.count'] || 0
  } catch (e: any) {
    alert('An error occurred while exporting, please try again.')
    yield put(exportDueDiligenceToExcelActions.failure(e))
  }

  if (totalCount > initialChunkSize) {
    const iterations = Math.ceil((totalCount - initialChunkSize) / chunkSize)

    const actions = Array(iterations)
      .fill(1)
      .map((_, i) => i)
      .map(
        (
          i
        ): (() => Generator<
          StrictEffect,
          { index: number; result: ISearchResult<ISecurity> },
          unknown
        >) =>
          function* () {
            const skip = i * chunkSize + initialChunkSize
            let top = chunkSize

            if (skip + top > totalCount) {
              top = totalCount - skip
            }

            const chunk = yield* call(
              search,
              'security' as const,
              {
                ...baseRequestParams,
                top,
                skip
              },
              options
            )
            return { index: i + 1, result: chunk as ISearchResult<ISecurity> }
          }
      )

    try {
      const results = yield* throttle(actions, 5)
      chunks.push(...results)
    } catch (e: any) {
      yield put(exportDueDiligenceToExcelActions.failure(e))
    }
  }

  const data = chunks
    .sort((a, b) => a.index - b.index)
    .reduce((a, x) => a.concat(x.result.value), [] as ISecurity[])

  const columnDefinitions = action.payload.columnDefinitions
  const columnDefinitionLookup = keyBy(columnDefinitions, (x) => x.id)
  const columnState = action.payload.columnState

  const selectedColumns = columnState
    .filter((x: any) => x.selected)
    .map((x: any) => columnDefinitionLookup[x.columnId])

  const exportColumns = selectedColumns
    .map((column: any): IExportDefinition<ISecurity>[] => {
      const getExportDefinition = (
        definition: IColumnDefinition
      ): IExportDefinition<ISecurity> => ({
        columnId: definition.id,
        columnName: definition.name,
        getValue: (item) => getValue(item, getMapping(definition))
      })

      return [getExportDefinition(column)]
    })
    .flat()

  const headers = exportColumns
    .filter((x: any) => x.columnName !== 'Action')
    .map((x: any) => x?.columnName)

  try {
    const mappedData = yield* all(
      data.map(function* (item) {
        const results = exportColumns
          .filter((x: any) => x.columnName !== 'Action')
          .map(function* (column: any) {
            const value = yield* column.getValue
              ? call(column.getValue, item, data)
              : column.getValueGenerator
              ? call(column.getValueGenerator, item)
              : call(() => Promise.resolve())

            if (value === undefined && column.dataMapping) {
              return getValue(item, column.dataMapping)
            }
            return typeof value === 'object' ? (value || '').toString() : value
          })

        return yield* all(results)
      })
    )
    const approvalStatusIndex = headers.indexOf('Approval Status')
    const idIndex = headers.indexOf('Action')
    const effectiveDateIndex = headers.indexOf('Approval Effective Date')
    const selectListIndex = headers.indexOf('Focus List')
    const selectEffectiveDateIndex = headers.indexOf(
      'Focus List Effective Date'
    )
    const lastUpdateOnIndex = headers.indexOf('Last Updated On')

    // Update the Due diligence column based on rcm_sec_id
    if (
      approvalStatusIndex !== undefined &&
      effectiveDateIndex !== undefined &&
      selectListIndex !== undefined &&
      selectEffectiveDateIndex !== undefined &&
      lastUpdateOnIndex !== undefined &&
      idIndex !== undefined &&
      approvalStatusIndex !== -1 &&
      selectListIndex !== -1 &&
      selectEffectiveDateIndex !== -1 &&
      lastUpdateOnIndex !== -1 &&
      idIndex !== -1 &&
      effectiveDateIndex !== -1
    ) {
      //Get DueDiligencesService
      const options: IApiOptions = yield call(getRockefellerApiOptions)
      const dueDiligenceRes = yield* call(() =>
        getDueDiligencesService(options)
      )

      // Update due diligence Column against matching rcm_sec_id

      for (const rowIndex in mappedData) {
        const rcmSecIdData = mappedData[rowIndex][approvalStatusIndex]
        const reqData = dueDiligenceRes?.data?.find(
          (x: any) => x.rcm_sec_id === rcmSecIdData
        )
        const hideIdIndex = true
        if (hideIdIndex) {
          mappedData[rowIndex][idIndex] = ''
          headers[idIndex] = ''
        }
        reqData?.approvalStatus !== undefined
          ? (mappedData[rowIndex][approvalStatusIndex] =
              Object.values(ApprovalStatus)[
                Object.keys(ApprovalStatus).indexOf(reqData?.approvalStatus)
              ])
          : (mappedData[rowIndex][approvalStatusIndex] = '--')

        reqData?.approvalEffectiveDate !== undefined
          ? (mappedData[rowIndex][effectiveDateIndex] =
              reqData?.approvalEffectiveDate !== null
                ? format(new Date(reqData?.approvalEffectiveDate), 'MM/dd/yyyy')
                : '--')
          : (mappedData[rowIndex][effectiveDateIndex] = '--')

        reqData?.selectList !== undefined
          ? (mappedData[rowIndex][selectListIndex] = reqData?.selectList)
          : (mappedData[rowIndex][selectListIndex] = '--')

        reqData?.selectListEffectiveDate !== undefined
          ? (mappedData[rowIndex][selectEffectiveDateIndex] =
              reqData?.selectListEffectiveDate !== null
                ? format(
                    new Date(reqData?.selectListEffectiveDate),
                    'MM/dd/yyyy'
                  )
                : '--')
          : (mappedData[rowIndex][selectEffectiveDateIndex] = '--')
      }
    }

    mappedData
      // .filter((x: any) => x.columnName !== 'Action')
      .unshift(headers)

    const filename = 'Export_Due_Diligence.xlsx'
    const wsName = 'Due_Diligence'
    yield call(
      // @ts-expect-error: Unreachable code error
      exportDataToExcel,
      {
        sheets: [{ name: wsName, data: mappedData }]
      },
      filename
    )
  } catch (e: any) {
    yield put(exportDueDiligenceToExcelActions.failure(e))
  }

  yield put(exportDueDiligenceToExcelActions.success())
}

function* handleShareClass() {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const filter: OdataPropertyFilterGroup = {
      and: [
        {
          operator: OdataFilterOperatorEnum.searchin,
          path: 'assettype',
          type: 'string',
          value: ['MUTUAL FUNDS']
        }
      ]
    }
    const params: ISearchParams = {
      orderBy: [
        { dataPath: 'search.score()', direction: 'desc' },
        { dataPath: 'id', direction: 'desc' }
      ],
      filters: [filter]
    }

    const response: ISearchResult<ISecurity> = yield call(
      search,
      'security' as const,
      {
        ...params,
        select: ['shareclass'],
        facets: ['shareclass, count:1000'],
        top: 0
      },
      options
    )

    if (response && response?.value) {
      yield put(getShareClassActions.success(response))
    } else {
      yield put(
        getShareClassActions.failure(new Error('unable to fetch share class'))
      )
    }
  } catch (e: any) {
    yield put(getShareClassActions.failure(e))
  }
}

function* handleIDDUpload(action: ReturnType<typeof uploadIDDActions.request>) {
  const options = yield* call(getRockefellerApiOptions)
  const { onUploadSuccess, onUploadFail, onUploadProgress, fileDoc } =
    action.payload
  try {
    const data = yield* call(
      uploadIDDFileService,
      fileDoc,
      options,
      onUploadProgress
    )
    onUploadSuccess(fileDoc.docFile)

    if (fileDoc.docFile.fileStatus === DragDropFileStatus.SUCCESS) {
      yield put(uploadIDDActions.success(data))
    } else {
      yield put(uploadIDDActions.failure(new Error(data.responseMessage)))
    }
  } catch (e: any) {
    onUploadFail(fileDoc.docFile)
    yield put(
      uploadIDDActions.failure(new Error(e?.response?.data?.responseMessage))
    )
  }
}

export const dueDiligenceSagas = [
  () => takeLatest(dueDiligenceFetchActions.request, handleFetchdueDiligence),
  () => takeLatest(updateDueDiligenceActions.request, handleUpdateDueDiligence),
  () => takeLatest(getAuditRequestActions.request, handleAuditRequestBySecId),
  () =>
    takeLatest(fetchApprovalStatusActions.request, handleApprovalStatusActions),
  () =>
    takeLatest(
      exportDueDiligenceToExcelActions.request,
      handleExportDueDiligenceToExcel
    ),
  () => takeLatest(getShareClassActions.request, handleShareClass),
  () => takeEvery(uploadIDDActions.request, handleIDDUpload)
]
