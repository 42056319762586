import { flow } from 'lodash'
import { AppState } from 'store'

export const rootSelector = (state: AppState) => state.modules.duediligence

export const getDueDiligencesFetchResult = flow(
  rootSelector,
  (x) => x.getDueDiligencesState
)
export const updateDueDiligencesState = flow(
  rootSelector,
  (x) => x.updateDueDiligencesState
)
export const getApprovalStatusState = flow(
  rootSelector,
  (x) => x.fetchApprovalStatusState
)
export const getAuditRequestByRcmSecIdState = flow(
  rootSelector,
  (x) => x.fetchAuditRequestByRcmSecIdState
)

export const getShareClassState = flow(rootSelector, (x) => x.shareClassState)
export const getUploadIDDState = flow(rootSelector, (x) => x.uploadIDDDetails)
